import React, { forwardRef, useState } from "react";

import moment from "moment";
import Swal from "sweetalert2";

import Collapsible, { CollapsibleForwardRefProps } from "~/components/Common/Collapsible";
import ExternalLinkIcon from "~/components/icons/ExternalLinkIcon";
import GreaterThanArrowIcon from "~/components/icons/GreaterThanArrowSmallIcon";
import LoadingSpinnerIcon from "~/components/icons/LoadingSpinnerIcon";
import { nursePaymentDetailDisplayName } from "~/components/nursePayments/constants";
import TrashIcon from "~/icons/TrashIcon";
import { deleteNursePayment } from "~/utils/api/v2/nursePayments";
import { NursePayment, NursePaymentDetail } from "~/utils/interfaces/nursePayments";
import { numberWithPeriods } from "~/utils/numbers";
import { capitalize } from "~/utils/texts";

type NursePaymentDetailCollapsibleProps = {
  nursePayment: NursePayment;
  onClick: () => void;
  date?: string;
  id?: number;
};

const NursePaymentDetailCollapsible = forwardRef(function NursePaymentDetailCollapsible(
  { nursePayment, onClick, date, id }: NursePaymentDetailCollapsibleProps,
  ref: React.Ref<CollapsibleForwardRefProps>,
) {
  const [loading, setLoading] = useState<boolean>(false);

  const onDeletePaymentClick = async (id: string) => {
    const result = await Swal.fire({
      title: "¿Estás seguro de querer eliminar este pago?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    });

    if (!result.isConfirmed) return;

    setLoading(true);
    try {
      const res = await deleteNursePayment(id);
      if ("error" in res) {
        await Swal.fire({
          title: "Error",
          icon: "error",
        });
      } else {
        await Swal.fire({
          title: "Pago eliminado",
          icon: "success",
        });
        window.location.reload();
      }
    } catch (error) {
      console.error("Error deleting payment:", error);
      await Swal.fire({
        title: "Error",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Collapsible
      head={
        <div
          className="flex items-center justify-between cursor-pointer my-3"
          onClick={onClick}
        >
          <p className="mb-0 font-medium text-lg">
            {nursePayment.appointment &&
              id !== undefined &&
              `Cita ${id + 1} - del ${moment(date).format("DD [de] MMMM, YYYY")}`}
            {!nursePayment.appointment && "Otros montos del día"}
          </p>
          <GreaterThanArrowIcon
            height={14.5}
            width={9}
          />
        </div>
      }
      ref={ref}
      initialState={true}
      key={nursePayment.id}
    >
      <div>
        {!!nursePayment.appointment && (
          <div className="flex justify-between items-center pb-3 border-b">
            <div className="flex text-sm">
              <p className="mr-4 mb-0">id</p>
              {nursePayment.appointment.id}
            </div>
            <a
              target="_blank"
              href={`/appointment/${nursePayment.appointment.id}`}
              rel="noreferrer"
              className="flex items-center text-sm"
            >
              Abrir{" "}
              <ExternalLinkIcon
                height={20}
                width={20}
                fill="blue"
              />
            </a>
          </div>
        )}
        {Object.entries(nursePayment.detail)
          .filter(([concept, amount]) => !!amount && concept !== "total")
          .map(([concept, amount]) => (
            <div
              key={concept}
              className="flex justify-between py-2 border-b text-sm"
            >
              <p className="mb-0">
                {concept in nursePaymentDetailDisplayName
                  ? nursePaymentDetailDisplayName[concept as keyof NursePaymentDetail]
                  : capitalize(concept.replace(/_/g, " "))}
              </p>
              <div className="flex items-center gap-x-2">
                <p className="mb-0">${numberWithPeriods(amount)}</p>
                {!loading && !nursePayment.appointment && (
                  <button
                    className="p-0.5 rounded-full hover:bg-gray-200"
                    onClick={() => onDeletePaymentClick(nursePayment.id)}
                >
                  <TrashIcon
                    color="#BDBDBD"
                    width={18}
                    />
                  </button>
                )}
                {loading && <LoadingSpinnerIcon className="mx-auto" />}
              </div>
            </div>
          ))}
        <div
          key="total"
          className="flex justify-between py-2 border-b text-sm"
        >
          <p className="mb-0 font-bold">Total</p>
          <p className="mb-0 font-bold">${numberWithPeriods(nursePayment.detail["total"])}</p>
        </div>
      </div>
    </Collapsible>
  );
});

export default NursePaymentDetailCollapsible;
